<template>
  <div
    class="container"
    v-title
    id="eleFaultChart"
    :data-title="$t('i18n.eleFaultChart')"
  >
    <div id="outer-title">{{ $t("i18n.eleFaultChart") }}</div>
    <jl-chart name="option" class="chartCircle" :columns="pieOption"></jl-chart>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlChart from "../../components/chart";
export default {
  name: "FaultTypeChart",
  components: {
    jlChart,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      pieOption: {
        title: t("i18n.eleFaultChart"),
        type: "pie",
        series: [
          {
            minAngle: 5, //最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
            avoidLabelOverlap: true, //是否启用防止标签重叠策略
            radius: ["35%", "55%"],
            itemStyle: {
              normal: {
                label: {
                  formatter: "{b}:{d}%",
                },
              },
            },
            data: [],
          },
        ],
      },
    });

    const init = async () => {
      let { data } = await proxy.$api.maintenance.getFaultTypeChart();
      state.pieOption.series[0].data = data;
    };

    init();

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss"></style>
